import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from '../../components/seo/page';
import Layout from '../../components/layouts'
import Info from '../../components/pages/products/Info';
import Capabilities from '../../components/pages/products/Capabilities';
import Benefits from '../../components/pages/products/Benefits';
import Menu from '../../components/pages/products/Menu';
import Form from '../../components/forms/Feedback'

import {
    Container
} from '../../components/styledComponents'

const Title = styled.h1`
  margin-bottom: 40px;
  @media (max-width: 991px) {
    margin-bottom: 15px;
  }
`

const StyledForm = styled(Form)`
  box-shadow: none;
  margin: 0;
  padding: 0 0 50px;
  justify-content: flex-start;
  align-items: flex-start;
  display: block;
  max-width: 368px;
  width: 100%;
  .form-title {
      text-align: left;
      width: 100%;
  }
  .feedback-btn{
      align-self:flex-start;
  }
`

export default class Contacts extends Component {

    componentDidMount() {
        document.body.style.background = "#fff"
    }

    getData(nodes, type) {

        let out = nodes.filter((el, idx) => {
            return el.node.frontmatter.block === type;
        });

        return out[0].node;
    }

    render() {
        const { data } =this.props;
        const nodes = data.allMarkdownRemark.edges;
        return (
            <Layout>
                <Seo title={'Aristotel'} meta={{
                    description: 'Комплекс видеофиксации потока',
                    keywords: '',
                    'og:url': 'products/aristotel'
                }} />
                <Container>
                    <Title>Продукты</Title>
                    <Menu current="aristotel" />
                    <Info data={this.getData(nodes, 'info')} />
                    <Capabilities data={this.getData(nodes, 'capabilities')} />
                    <Benefits data={this.getData(nodes, 'benefits')} />
                    <StyledForm className="page-form" simple={true} title="Узнать подробнее" />
                </Container>
            </Layout>
        )
    }
}

export const query = graphql`
  query getProductDataAristotel {
    allMarkdownRemark (
      filter: {
        frontmatter: {section: {eq: "aristotel"}}
      }
      sort: {fields: [frontmatter___main], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
          }
          html
        }
      }
    }
  }
` 